import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import { StrapiProduct } from '@/class/strapiProduct';

@Component({
  components: {
    DunatiLoaderComponent
  },
})
export default class CardProductSComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public data: StrapiProduct;
  public imageLoaded: boolean = false;
  public hover: boolean = false;
  public saleImg: string = '/assets/images/product_detail/SALE.svg';
  public placeholderImg: string = 'https://cdn-images-1.medium.com/max/80/1*xjGrvQSXvj72W4zD6IWzfg.jpeg';

  public mounted() {}

  public details(slug: string) {
    let redirectName: string = 'products-chair-details';

    if (this.data.category && this.data.category.name === 'mesas') {
      redirectName = 'products-table-details';
    } else if (this.data.category && this.data.category.name === 'almacenamiento') {
      redirectName = 'products-storage-details';
    } else if (this.data.category && this.data.category.name === 'sofas y sillas multiuso') {
      redirectName = 'products-multipurpose-chair-details';
    }

    this.$router.push({ name: redirectName, params: { subcategory: this.data.subcategory.slug, id: slug } });
  }

  public onLoaded() {
    this.imageLoaded = true;
  }

  public getSrc(type: 'url' | 'small' | 'medium' = 'url'): string {
    let noSecondary: boolean = this.hover && !this.data.secondaryImg;

    return !this.hover || noSecondary? this.data.img[type] : this.data.secondaryImg[type];
  }
}

