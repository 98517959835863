import { Category } from './category';
import { SectionCollage } from './sectionCollage';
import { SectionColor } from './sectionColor';
import { SectionComparative } from './sectionComparative';
import { SectionDownload } from './sectionDownload';
import { SectionGalery } from './sectionGalery';
import { SectionImageText } from './sectionImageText';
import { SectionPhotoPhoto } from './sectionPhotoPhoto';
import { SectionPhotoText } from './sectionPhotoText';
import { SectionTextOnly } from './sectionTextOnly';
import { SectionTextText } from './sectionTextText';
import { StrapiFile } from './strapiFile';
import { Subcategory } from './subcategory';

export class StrapiProduct {
  public id!: string;
  public name!: string;
  public metaDescription!: string;
  public slug!: string;
  public relatedSlug!: string;
  public status!: string;
  public evaluation!: number;
  public img!: StrapiFile;
  public secondaryImg!: StrapiFile;
  public showRelatedProducts!: boolean;
  public colors!: number;

  public price!: number;

  public category!: Category;
  public subcategory!: Subcategory;

  public sections: any[] = []; 

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.metaDescription = data.meta_description || '';
      this.slug = data.slug;
      this.relatedSlug = data.related_slug;
      this.evaluation = +data.evaluation;
      this.showRelatedProducts = data.show_related_products? true : false;
      this.colors = +data.colors;
      this.price = +data.price;

      if (data.category) {
        this.category = new Category(data.category);
      }

      if (data.subcategory) {
        this.subcategory = new Subcategory(data.subcategory);
      }

      if (data.img) {
        this.img = new StrapiFile(data.img);
      }

      if (data.secondary_img) {
        this.secondaryImg = new StrapiFile(data.secondary_img);
      }


      if (data.galery) {
        data.galery.section_type = 'galery';
        this.sections.push(new SectionGalery(data.galery));
      }

      if (data.text_only) {
        data.text_only.forEach((element: any) => {
          element.section_type = 'text_only';
          this.sections.push(new SectionTextOnly(element));
        });
      }

      if (data.photo_text) {
        data.photo_text.forEach((element: any) => {
          element.section_type = 'photo_text';
          this.sections.push(new SectionPhotoText(element));
        });
      }

      if (data.comparative) {
        data.comparative.forEach((element: any) => {
          element.section_type = 'comparative';
          this.sections.push(new SectionComparative(element));
        });
      }

      if (data.image_text) {
        data.image_text.forEach((element: any) => {
          element.section_type = 'image_text';
          this.sections.push(new SectionImageText(element));
        });
      }

      if (data.download) {
        data.download.section_type = 'download';
        this.sections.push(new SectionDownload(data.download));
      }

      if (data.color) {
        data.color.forEach((element: any) => {
          element.section_type = 'color';
          this.sections.push(new SectionColor(element));
        });
      }

      if (data.collage) {
        data.collage.forEach((element: any) => {
          element.section_type = 'collage';
          this.sections.push(new SectionCollage(element));
        });
      }

      if (data.photo_photo) {
        data.photo_photo.forEach((element: any) => {
          element.section_type = 'photo_photo';
          this.sections.push(new SectionPhotoPhoto(element));
        });
      }

      if (data.text_text) {
        data.text_text.forEach((element: any) => {
          element.section_type = 'text_text';
          this.sections.push(new SectionTextText(element));
        });
      }


      this.sections = this.sections.sort((a: any, b: any) => parseInt(a.position) - parseInt(b.position));
      
      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
