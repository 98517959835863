export class Category {
  public id!: number;
  public name!: string;
  public metaDescription!: string;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.metaDescription = data.meta_description || '';

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
