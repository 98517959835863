import { StrapiFile } from './strapiFile';

export class SectionDownload {
  public id!: string;
  public position!: number;
  public sectionType!: string;

  public catalogFile!: StrapiFile;
  public dataSheetFile!: StrapiFile;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.position = +data.position || 100;
      this.sectionType = data.section_type;

      if (data.data_sheet_file) {
        this.dataSheetFile = new StrapiFile(data.data_sheet_file)
      }

      if (data.catalog_file) {
        this.catalogFile = new StrapiFile(data.catalog_file)
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
