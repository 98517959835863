import { StrapiFile } from './strapiFile';

export class Color {
  public id!: string;
  public name!: string;
  public file!: StrapiFile;
  public colorFile!: StrapiFile;

  public updatedAt!: string;
  public createdAt!: string;


  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;

      if (data.file) {
        this.file = new StrapiFile(data.file);
      }

      if (data.color_file) {
        this.colorFile = new StrapiFile(data.color_file);
      }
      
      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}

export class SectionColor {
  public id!: string;
  public title!: string;
  public textDark!: boolean;
  public background!: string;
  public position!: number;
  public sectionType!: string;

  public colors: Color[] = [];
  public currentColor!: Color;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.textDark = data.text_dark? true : false;
      this.background = data.background;
      this.position = +data.position;
      this.sectionType = data.section_type;

      if (data.colors) {
        data.colors.forEach((x: any) => {
          this.colors.push(new Color(x))
        });
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }

  get colorUrls(): string[] {
    if (this.colors.length) {
      return this.colors.map((x: Color) => x.colorFile.url);
    }

    return [];
  }
}