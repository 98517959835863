import { getImgByType } from '@/util/head';

export class StrapiFile {
  public id!: string;
  public name!: string;
  public alternativeText!: string;
  public caption!: string;
  public ext!: string;
  public hash!: string;
  public height!: number;
  public width!: number;
  public mime!: string;
  public size!: number;

  public url!: string;
  public small!: string;
  public medium!: string;
  public large!: string;
  public thumbnail!: string;

  public smallHeight!: number;
  public smallWidth!: number;

  public updatedAt!: string;
  public createdAt!: string;


  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.alternativeText = data.alternativeText;
      this.caption = data.caption;
      this.ext = data.ext;
      this.hash = data.hash;
      this.height = +data.height;
      this.width = +data.width;
      this.mime = data.mime;
      this.size = +data.size;

      this.url = getImgByType(data) || '';
      this.small = getImgByType(data, 'small') || this.url;
      this.medium = getImgByType(data, 'medium') || this.url;
      this.large = getImgByType(data, 'large') || this.url;
      this.thumbnail = getImgByType(data, 'thumbnail') || this.url;

      if (data.formats &&  data.formats.small) {
        this.smallHeight = data.formats.small.height;
        this.smallWidth = data.formats.small.width;
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }

  get title(): string {
    if (this.caption !== "") {
      return this.caption;
    } else if(this.name) {
      const cover = (this.name + '').split('/');
      const img = cover[cover.length - 1].split('.')[0];
      return `${img.replace(/-/g, ' ')}`;
    } else {
      return '';
    }
  }

  get alt(): string {
    if (this.alternativeText !== "") {
      return this.alternativeText;
    } else if(this.name) {
      const cover = (this.name + '').split('/');
      const img = cover[cover.length - 1].split('.')[0];
      return `${img.replace(/-/g, ' ')}`;
    } else {
      return '';
    }
  }
}
